<template>
    <form autocomplete="off">
        <div class='row'>
            <div class='col-md'>
                <form-input label="Tên khóa đào tạo" :required='true' :model="formModel" attribute="name" :errors="formErrors"></form-input>
            </div>
             <div class='col-md'>
                <form-select-multiple :close-on-select='false' :required='true'  label="Loại hình đào tạo"  :model="formModel" attribute="contentTypes" :errors="formErrors" :options="contentEduGenderOptions" />
            </div>
        </div> 
        <div class="row">
            <div class='col-md'>
                <form-date-time label="Ngày bắt đầu"  :required='true' :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
            </div>
            <div class='col-md'>
                <form-date-time label="Ngày kết thúc" :required='true' :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
            </div>
        </div>
        <div class='row'>
            <div class='col-md-6'>
                <form-input label="Cơ sở đào tạo" :required='true' :model="formModel" attribute="site_name" :errors="formErrors"></form-input>
            </div>
        </div>
        <div class="form-group text-right">
            <b-button variant="secondary" class="ml-1" @click="$emit('close')">
                Đóng
            </b-button>
            <b-button variant="primary" class="ml-1" @click="saveNext">
                <b-spinner v-if="updateLoading || saveLoading" small></b-spinner>
                Lưu và tiếp tục 
                <i class="fa fa-arrow-right"></i>
            </b-button>
        </div>
    </form>
</template>

<script>
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';

    export default {
        components: {},
        props: ["edu", "params"],
        mixins: [crud, role],
        data: function () {
            return {
                service: '/edu',
                reloadBus :'edu-gender',
                defaultFormModel: {
                    contentTypes: null,
                    edu_gender: null,
                    group: 'gender',
                     code : null,
                },
                contentEduGenderOptions: [
                    {value: 1, text: "1. Nâng cao bình đẳng giới và trao quyền cho phụ nữ"},
                    {value: 2, text: "2. Giảm thiểu bạo lực giới"},
                ],
            };
        },
        computed: {
        },
        watch: {
        },
        methods: {
            saveNext() {
                this.save().then(data => {
                    if (data) {
                        this.$emit('next', 'student', data);
                    }
                });
            },
            beforeUpdate(data) {
                data.start_time = data.start_time ? data.start_time * 1000 : null;
                data.end_time = data.end_time ? data.end_time * 1000 : null;
            },
        },
        mounted() {
            this.formErrors = {};
            this.formModel = {};
            this.formModel = Object.assign({}, this.defaultFormModel);
            this.formModel.contentTypes = [];
            if (this.edu) {
                this.updateLoading = true;
                this.$service.get(`${this.service}/get?id=${this.edu.id}`).then(response => {
                    response.data.start_time = response.data.start_time ? response.data.start_time * 1000 : null;
                    response.data.end_time = response.data.end_time ? response.data.end_time * 1000 : null;
                    this.formModel = response.data;
                    console.log(this.formModel);
                }).finally(() => {
                    this.updateLoading = false;
                });
            }
        }
    }
</script>